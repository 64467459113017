@use "sass:math";

.table thead th,
.table td {
  vertical-align: middle;
  position: relative;
}

.table {
  th .filter {
    margin-top: math.div($layout-spacer-x, 4);
    height: auto;
    padding: 0.3rem 0.5rem;
  }
}

.caret-4-asc,
.caret-4-desc {
  font-family: "Linearicons-Free";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin-left: math.div($layout-spacer-x, 4);
}

.table .sortable:hover {
  .caret-4-asc,
  .caret-4-desc {
    color: $primary;
  }
}

.caret-4-asc {
  @extend .lnr-chevron-up !optional;
}

.caret-4-desc {
  @extend .lnr-chevron-down !optional;
}

// Table sizing

.table-lg {
  th,
  td {
    padding: ($table-cell-padding * 2) ($table-cell-padding * 1.5);
  }
}
