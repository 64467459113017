// Grayscale Filter

.filter-grayscale-5 {
  filter: grayscale(5%) !important;
}

.filter-grayscale-10 {
  filter: grayscale(10%) !important;
}

.filter-grayscale-20 {
  filter: grayscale(20%) !important;
}

.filter-grayscale-30 {
  filter: grayscale(30%) !important;
}

.filter-grayscale-40 {
  filter: grayscale(40%) !important;
}

.filter-grayscale-50 {
  filter: grayscale(50%) !important;
}

.filter-grayscale-80 {
  filter: grayscale(80%) !important;
}

.filter-grayscale-100 {
  filter: grayscale(100%) !important;
}
