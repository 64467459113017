.fake-input {
  width: 100%;
  border-color: hsl(0, 0%, 90%);
  border-style: solid;
  border-width: 1px;
  border-radius: 0.25rem;
  padding: $input-padding-y $input-padding-x;
  color: hsl(0, 0%, 60%);
  background-color: hsl(0, 0%, 95%);

  p {
    margin-bottom: 0;
  }
}
