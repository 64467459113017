// Sidebar Light

.app-sidebar {
  &.sidebar-text-light {
    border-right: 0 !important;

    .app-sidebar__heading {
      color: rgba(255, 255, 255, 0.6);

      &::before {
        background: rgba(255, 255, 255, 0.2) !important;
      }
    }

    .ps__thumb-y {
      background: rgba(255, 255, 255, 0.3);
    }

    .ps__rail-y:hover {
      .ps__thumb-y {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .app-header__logo {
      .logo-src {
        background: url("../../../../assets/utils/images/logo-no-background.svg");
      }

      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
