// Header Mega Menu

@use "sass:math";

.header-megamenu {
  &.nav {
    & > li > .nav-link {
      color: $gray-600;
      padding-left: math.div($nav-link-padding-x, 1.5);
      padding-right: math.div($nav-link-padding-x, 1.5);

      .rounded-pill {
        padding: 5px 7px;
      }

      &:hover {
        color: $gray-800;
      }

      svg {
        margin-top: 1px;
      }
    }
  }
}
