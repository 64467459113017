.app-error {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;
  &__embedded {
    height: calc(100vh - 60px) !important;
  }

  background-color: $gray-900;
  color: $white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__barcode {
    height: 150px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    padding-inline: $grid-gutter-width;

    &__svg {
      position: relative;
      max-width: 100%;
      top: -50%;
    }

    &__status {
      font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      font-size: 130px;
      line-height: 90px;
    }
  }

  &__text {
    margin-top: 70px;
    max-width: 650px;
    text-align: center;
    padding: $grid-gutter-width;
  }
}
