// Footer base

@use "sass:math";

.app-footer {
  position: relative;
  width: 100%;
  height: $app-header-height;
  padding-top: 20px;
  box-sizing: content-box;
  margin-top: auto;

  &__inner {
    box-shadow: 3px -7px 30px 0px $gray-200;
    padding: 0 $layout-spacer-x 0 math.div($layout-spacer-x, 2);
    height: 100%;
    transition: all 0.2s;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $layout-spacer-x;
  }
}

// .app-footer {
//   height: $app-header-height;

//   .app-footer__inner {
//     padding: 0 $layout-spacer-x 0 math.div($layout-spacer-x, 2);
//     height: $app-header-height;
//     transition: all .2s;

//     display: flex;
//     align-content: center;
//     align-items: center;

//     .app-footer-left {
//       display: flex;
//       align-items: center;
//     }

//     .app-footer-right {
//       margin-left: auto;
//       display: flex;
//     }
//   }
// }

// // Footer Dots Section

// .footer-dots {
//   display: flex;
//   align-items: center;
//   align-content: center;

//   .dots-separator {
//     height: 40px;
//     margin: 0 math.div($layout-spacer-x, 2.5);
//     width: 1px;
//     background: $gray-200;
//   }
// }

// .dot-btn-wrapper {
//   padding: .5rem;
//   position: relative;
//   display: flex;
//   opacity: .7;
//   transition: opacity .2s;

//   .badge-abs {
//     right: 50%;

//     &.badge-dot-sm {
//       top: -2px;
//       margin-right: -3px;
//     }
//   }

//   .dot-btn-icon {
//     font-size: 1.8rem;
//   }

//   &:hover {
//     text-decoration: none;
//     opacity: 1;
//   }
// }

// // Footer Modifiers

// @import "fixed-footer";
