// Main Base

@use "sass:math";

.app-main {
  z-index: 8;
  position: relative;
  display: flex;

  .app-main__outer {
    flex: 1;
    flex-direction: column;
    display: flex;
    z-index: 12;
    width: 1px;
  }

  .app-main__inner {
    position: relative;
    padding: $grid-gutter-width $grid-gutter-width 0;
    flex: 1;

    & > .RRT__container {
      & > .RRT__panel {
        margin-top: $grid-gutter-width;
      }

      & > .body-tabs-layout {
        .RRT__tab {
          margin-top: (-(math.div($layout-spacer-x, 3)));
        }
      }
    }
  }
}

// Themes

@import "themes/main-white";
