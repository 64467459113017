.burger-button {
  background: none;
  color: $gray-600;
  width: 56px;
  height: 56px;
  border: none;
  position: relative;

  &__icon {
    position: absolute;
    opacity: 1;
    transition: opacity ease-in-out 0.3s;

    &_hidden {
      opacity: 0;
    }
  }
}
