:root {
  --toastify-color-light: #{$white} !important;
  --toastify-color-dark: #{$gray-900} !important;
  --toastify-color-info: #{$blue-600} !important;
  --toastify-color-success: #{$green-600} !important;
  --toastify-color-warning: #{$yellow-600} !important;
  --toastify-color-error: #{$red-600} !important;

  --toastify-toast-width: 500px !important; // default 320
}
