// User Pages

.app-logo {
  background: url("../../assets/utils/images/logo-2-white.svg");
  max-width: calc(2 * $logo-width);
  width: 100%;
  height: calc(2 * $logo-height);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  position: absolute;
  top: 20px;
  left: 20px;

  @media (max-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    margin-inline: auto;
  }
}

.app-login-box {
  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: 0.6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}
