// Navigation Menu Vertical

.vertical-nav-menu {
  position: relative;

  &::after {
    content: " ";
    pointer-events: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    color: $gray-500;
    background-color: transparent;
    padding: 0.5rem;
    border: none;
    border-radius: $border-radius;
    transition:
      background-color ease-in-out 0.3s,
      color ease-in-out 0.3s,
      padding ease-in-out 0.3s;

    &:hover,
    &_active {
      color: $gray-300;
      background-color: $gray-700;
      font-weight: 400;
    }

    &__label {
      max-width: 1000px;
      margin-left: 1em;
      overflow-x: hidden;
      text-wrap: nowrap;
      transition:
        max-width ease-in-out 0.3s,
        margin-left ease-in-out 0.3s;
    }

    &__icon {
      min-width: 16px;
    }
  }
}
